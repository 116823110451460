<template>
  <div class="select-wrapper">
    <input
      ref="input"
      class="default-input with-icon country-phone"
      placeholder="Phone Number"
      :name="name"
      type="text"
      :value="usingField.value.value"
      @input="onChange"
    />
    <ErrorMessage :name="name" v-slot="{ message }">
      <p class="error-message">
        {{ message }}
      </p>
    </ErrorMessage>
    <p v-if="usingField.value.value && !isValid" class="error-message">
      Invalid phone number
    </p>
    <button class="select__code" ref="code" type="button" @click="onClickCode">
      <div class="select__code__flag" v-html="selected.flag"></div>
      <span class="select__code__label">+{{ selected.code }}</span>
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'select__arrow'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 5.19L2.031 1.22a.747.747 0 00-1.06 0 .754.754 0 000 1.062l4.498 4.5c.284.285.74.29 1.034.022l4.528-4.519a.75.75 0 00.219-.53.75.75 0 00-1.278-.532L6 5.19z" fill="#fff"/></svg>
    </button>
    <div class="select__options" ref="options">
      <div class="select__search">
        <input
          class="select__input"
          v-model="search"
          placeholder="Country or code"
        />
      </div>
      <ul class="select__list">
        <li
          class="select__item"
          v-for="(c, i) in filteredCountries"
          :key="i"
          @click="onSelect(c)"
        >
          <div class="select__item__left">
            <div class="select__code__flag" v-html="getFlag(c)"></div>
            <span class="select__code__label">+{{ c.code }}</span>
          </div>
          <span class="select__item__label">{{ c.country }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import countries from '@/data/countries.json'
import { useField, ErrorMessage } from 'vee-validate'
import { getCountryByIp } from '@/services/other.service'
import { isValidPhoneNumber, getCountryCallingCode } from 'libphonenumber-js'

export default {
  name: 'SelectCountryPhone',
  components: { ErrorMessage },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    isValid: Boolean,
    name: String,
  },
  data() {
    return {
      countries: countries,
      search: '',
      selected: {
        flag: require(`country-flag-icons/string/3x2/US`).default,
        code: 1,
      },
      usingField: {
        value: {},
      },
      country: {},
    }
  },
  computed: {
    filteredCountries() {
      const s = this.search.replace('+', '').trim()
      return this.countries.filter(
        (i) =>
          i.country.toLowerCase().includes(this.search.toLowerCase()) ||
          i.code.includes(s)
      )
    },
  },
  created() {
    getCountryByIp().then((res) => {
      this.selected = {
        flag: require(`country-flag-icons/string/3x2/${res.country}`).default,
        code: getCountryCallingCode(res.country),
      }
    })
  },
  async mounted() {
    this.usingField = useField(this.name, undefined, {
      initialValue: this.value,
    })
    document.addEventListener('click', this.outsideClick)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.outsideClick)
  },
  methods: {
    onSelect(c) {
      this.selected = {
        flag: require(`country-flag-icons/string/3x2/${c['iso']}`).default,
        code: c.code,
      }
      const phone = '+' + this.selected.code + this.value
      this.$emit('phoneValue', phone)
      this.$emit('validPhone', isValidPhoneNumber(phone))
    },

    onChange(e) {
      const phone = '+' + this.selected.code + e.target.value
      this.usingField.handleChange(e)
      this.$emit('phoneValue', phone)
      this.$emit('validPhone', isValidPhoneNumber(phone))
    },

    getFlag(c) {
      const t = require(`country-flag-icons/string/3x2/${c['iso']}`)
      return t ? t.default : console.log('f: ', c)
    },

    onClickCode() {
      if (
        !this.$refs.options.style.display ||
        this.$refs.options.style.display === 'none'
      ) {
        this.$refs.options.style.display = 'block'
        if (
          this.$refs.options.getBoundingClientRect().bottom >
          document.body.clientHeight
        ) {
          this.$refs.options.style.top = 'unset'
          this.$refs.options.style.bottom = '68px'
        }
      } else this.$refs.options.style.display = 'none'
    },

    outsideClick(event) {
      if (
        event.target.closest('.select__options') !== this.$refs.options &&
        event.target.closest('.select__code') !== this.$refs.code
      ) {
        this.$refs.options.style.display = 'none'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.default-input.country-phone {
  padding-left: 110px;
  text-align: left;
}
.select {
  &-wrapper {
    position: relative;
  }

  &__options {
    display: none;
    position: absolute;
    top: 68px;
    z-index: 22;
    background: var(--color-white);
    border-radius: 26px;
    width: 100%;
    padding: 24px;
  }

  &__input {
    background-image: url('~@/assets/icons/search-black.svg');
    background-size: 22px 22px;
    background-position-x: 0px;
    background-repeat: no-repeat;
    outline: none;
    border: none;
    padding: 0 0 0 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #134e5e;
  }

  &__search {
    border-bottom: 1px solid rgba(#134e5e, 0.1);
    padding-bottom: 12px;
  }

  &__code {
    position: absolute;
    left: 18px;
    top: 31px;
    background: inherit;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    transform: translateY(-50%);

    &__label {
      margin-left: 4px;
    }

    &__flag {
      width: 27px;
      height: 16px;
      display: flex;
      align-items: center;
    }
  }

  &__arrow {
    margin-left: 3px;
    margin-bottom: 3px;
  }

  &__list {
    overflow: auto;
    max-height: 165px;
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 9px 0;
    border-bottom: 1px solid rgba(#134e5e, 0.1);
    transition: all 300ms ease-in-out;

    &:hover {
      background-color: snow;
    }

    &__left {
      display: flex;
      align-items: center;
      width: 100px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #134e5e;
    }

    &__label {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #134e5e;
    }
  }
}
</style>

<template>
  <div class="leads">
    <div class="leads__content">
      <p class="leads__title" v-if="!hideInfo">
        We've crafted your personalized plan - designed to help guide you
        towards {{ goals }}
      </p>
      <Form class="leads__form" @submit="onSubmit" :validation-schema="schema">
        <div class="leads__inputs">
          <div>
            <Field
              type="text"
              name="name"
              class="default-input with-icon name"
              placeholder="First Name"
              v-model="form.name"
            />
            <ErrorMessage name="name" v-slot="{ message }">
              <p class="error-message">
                {{ message }}
              </p>
            </ErrorMessage>
          </div>
          <div>
            <Field
              class="default-input with-icon"
              placeholder="E-mail"
              type="email"
              name="email"
              v-model="form.email"
            />
            <ErrorMessage name="email" v-slot="{ message }">
              <p class="error-message">
                {{ message }}
              </p>
            </ErrorMessage>
          </div>
          <select-country-phone
            @phoneValue="onPhoneChange"
            @validPhone="onValidPhone"
            :isValid="isValidPhone"
            name="phone"
            type="text"
          />
        </div>
        <div class="login__input-wrapper input-wrapper input-wrapper--login">
          <button type="submit">
            <span class="text">See my plan</span>
          </button>
        </div>
      </Form>
      <p class="leads__info" v-if="!hideInfo">
        By providing your email and phone number you consent to newsletters and
        marketing communication by Mental Future as described in our
        <router-link :to="{ name: 'TermsAndConditions' }"> T&C </router-link>
        and
        <router-link :to="{ name: 'PrivacyAndPolicy' }"
          >Privacy Policy</router-link
        >. We respect your privacy and do not tolerate spam. We will never share
        or sell your information to third parties.
      </p>
    </div>
  </div>
</template>

<script>
import SelectCountryPhone from '@/components/forms/SelectCountryPhone.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { mapState } from 'vuex'
import * as yup from 'yup'
import { goalTitleLeads } from '@/components/quiz/quiz-data'
import { postLead } from '@/services/lead.service'

const schema = yup.object({
  email: yup.string().required().email(),
  name: yup.string().required(),
  phone: yup.string().required(),
})

export default {
  name: 'LeadsCreateAccount',
  components: { SelectCountryPhone, Form, Field, ErrorMessage },
  props: {
    hideInfo: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: String,
      default: '',
    },
    vsl_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {},
      schema,
      isValidPhone: false,
    }
  },
  computed: {
    ...mapState('quiz', ['quizForm', 'fields', 'quiz', 'quizData']),

    goals() {
      if (this.quizForm.goal && this.quizForm.goal.length > 1)
        return 'reaching your goals'
      return this.fields.goal
        .filter((i) => this.quizForm.goal.includes(i.value))
        .map((i) => goalTitleLeads[i.text])
        .join(',')
    },
  },
  watch: {
    quizForm: {
      immediate: true,
      handler() {
        if (this.quizForm.email) {
          this.form.email = this.quizForm.email
        }
      },
    },
  },
  methods: {
    onSubmit() {
      if (!this.isValidPhone) return
      const { utm_campaign, utm_medium, utm_source } = this.$route.query
      postLead({
        ...this.quizForm,
        ...this.form,
        statistic_id: this.quiz.statistic_id,
        utm_source,
        utm_medium,
        utm_campaign,
      })
        .then(() => {
          fbq('track', 'Lead')
          localStorage.setItem('statistic_id', this.quiz.statistic_id)
          gtag_report_conversion()
          this.$router.push({
            path: `/thank-you`,
            query: {
              ...this.$route.query,
              statistic_id: this.quiz.statistic_id,
              slug: this.quizData.slug,
              redirect: this.redirect,
              vsl_id: this.vsl_id,
            },
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onPhoneChange(v) {
      this.form.phone = v
    },
    onValidPhone(v) {
      this.isValidPhone = v
    },
  },
}
</script>

<style lang="scss" scoped>
.leads {
  margin-top: 86px;
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 64px;
    color: var(--color-white);
  }

  &__content {
    max-width: 327px;
    margin: 0 auto;
  }

  &__inputs {
    display: grid;
    gap: 16px;
  }

  &__info {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    margin-top: 64px;
    padding-bottom: 40px;

    > a {
      text-decoration: underline;
    }
  }
}
</style>
